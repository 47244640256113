// src/components/pages/PatientIssues.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { collection, addDoc, getDocs, updateDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';

const PatientIssues = () => {
  const { id } = useParams();
  const [issues, setIssues] = useState([]);
  const [newIssue, setNewIssue] = useState({
    title: '',
    description: '',
    startDate: '',
    severity: '',
    category: '',
    notes: '',
  });
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [expandedIssue, setExpandedIssue] = useState(null);

  const fetchIssues = async () => {
    const issuesCollection = collection(db, `patients/${id}/patientIssues`);
    const issuesSnapshot = await getDocs(issuesCollection);
    const issuesList = issuesSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setIssues(issuesList);
    setLoading(false);
  };

  useEffect(() => {
    fetchIssues();
  }, [id]);

  const handleAddIssue = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, `patients/${id}/patientIssues`), {
        ...newIssue,
        resolved: false,
        startDate: newIssue.startDate ? new Date(newIssue.startDate) : null,
        resolvedDate: null,
        timestamp: new Date(),
      });
      setNewIssue({
        title: '',
        description: '',
        startDate: '',
        severity: '',
        category: '',
        notes: '',
      });
      fetchIssues();
    } catch (error) {
      console.error('Error adding issue:', error);
    }
  };

  const handleResolveIssue = async (issueId) => {
    try {
      const issueRef = doc(db, `patients/${id}/patientIssues`, issueId);
      await updateDoc(issueRef, { resolved: true, resolvedDate: new Date() });
      fetchIssues();
    } catch (error) {
      console.error('Error resolving issue:', error);
    }
  };

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const toggleExpandIssue = (issueId) => {
    setExpandedIssue(expandedIssue === issueId ? null : issueId);
  };

  return (
    <div className="p-4 bg-white dark:bg-gray-800 rounded-md shadow-md">
      <h2 className="text-xl font-bold mb-4">Patient Issues</h2>
      <button
        onClick={toggleForm}
        className="mb-4 text-sm text-blue-500 font-bold"
      >
        {showForm ? 'Hide Form' : 'Show Form'}
      </button>
      {showForm && (
        <form onSubmit={handleAddIssue} className="mb-4">
          <input
            type="text"
            value={newIssue.title}
            onChange={(e) => setNewIssue({ ...newIssue, title: e.target.value })}
            placeholder="Issue Title"
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
            required
          />
          <textarea
            value={newIssue.description}
            onChange={(e) => setNewIssue({ ...newIssue, description: e.target.value })}
            placeholder="Description"
            className="w-full px-3 py-2 mt-2 border border-gray-300 rounded-md"
          />
          <input
            type="date"
            value={newIssue.startDate}
            onChange={(e) => setNewIssue({ ...newIssue, startDate: e.target.value })}
            className="w-full px-3 py-2 mt-2 border border-gray-300 rounded-md"
          />
          <input
            type="text"
            value={newIssue.severity}
            onChange={(e) => setNewIssue({ ...newIssue, severity: e.target.value })}
            placeholder="Severity"
            className="w-full px-3 py-2 mt-2 border border-gray-300 rounded-md"
          />
          <input
            type="text"
            value={newIssue.category}
            onChange={(e) => setNewIssue({ ...newIssue, category: e.target.value })}
            placeholder="Category"
            className="w-full px-3 py-2 mt-2 border border-gray-300 rounded-md"
          />
          <textarea
            value={newIssue.notes}
            onChange={(e) => setNewIssue({ ...newIssue, notes: e.target.value })}
            placeholder="Additional Notes"
            className="w-full px-3 py-2 mt-2 border border-gray-300 rounded-md"
          />
          <button
            type="submit"
            className="mt-2 px-4 py-2 bg-indigo-600 text-white rounded-md"
          >
            Add Issue
          </button>
        </form>
      )}
      {loading ? (
        <p>Loading issues...</p>
      ) : (
        <ul>
          {issues.map(issue => (
            <li key={issue.id} className="mb-2">
              <div onClick={() => toggleExpandIssue(issue.id)} className="cursor-pointer">
                <span className={issue.resolved ? 'line-through' : ''}>
                  {issue.title} (Started: {issue.startDate ? new Date(issue.startDate.seconds * 1000).toLocaleDateString() : 'N/A'})
                </span>
              </div>
              {expandedIssue === issue.id && (
                <div className="mt-2 ml-4">
                  <p>Description: {issue.description || 'N/A'}</p>
                  <p>Severity: {issue.severity || 'N/A'}</p>
                  <p>Category: {issue.category || 'N/A'}</p>
                  <p>Notes: {issue.notes || 'N/A'}</p>
                  {!issue.resolved && (
                    <button
                      onClick={() => handleResolveIssue(issue.id)}
                      className="mt-2 px-2 py-1 bg-green-600 text-white rounded-md"
                    >
                      Resolve
                    </button>
                  )}
                </div>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default PatientIssues;