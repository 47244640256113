import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, query, where, doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { List, Avatar, DatePicker } from 'antd';
import { TeamOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

const PatientListComponent = ({ providerId }) => {
  const [patients, setPatients] = useState([]);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const navigate = useNavigate();

  useEffect(() => {
    fetchPatients(selectedDate);
  }, [selectedDate, providerId]);

  const fetchPatients = async (date) => {
    if (!providerId) return;

    try {
      const formattedDate = date.format('YYYY-MM-DD');
      const q = query(
        collection(db, 'appointments'),
        where('providerId', '==', providerId),
        where('date', '==', formattedDate)
      );
      const snapshot = await getDocs(q);

      const patients = await Promise.all(snapshot.docs.map(async (appointmentDoc) => {
        const data = appointmentDoc.data();
        const patientRef = doc(db, 'patients', data.patientId);
        const patientDoc = await getDoc(patientRef);
        const patientData = patientDoc.exists() ? patientDoc.data() : {};

        const isLate = checkIfLate(data.start.toDate(), data.checkInStatus);

        return {
          id: data.patientId,
          firstName: patientData.firstName || 'N/A',
          lastName: patientData.lastName || 'N/A',
          email: patientData.email || 'N/A',
          photoUrl: patientData.photoUrl || '',
          checkInStatus: isLate ? 'late' : data.checkInStatus || 'no-status',
          start: data.start.toDate()
        };
      }));

      // Sort patients by start time
      patients.sort((a, b) => a.start - b.start);

      setPatients(patients);
    } catch (error) {
      console.error('Error fetching patients:', error);
    }
  };

  const checkIfLate = (startTime, checkInStatus) => {
    const currentTime = new Date();
    return currentTime > startTime && checkInStatus !== 'checked-in';
  };

  const handlePatientClick = (id) => {
    navigate(`/dashboard/${id}`);
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case 'checked-in':
        return { color: 'green', fontWeight: 'bold' };
      case 'checked-out':
        return { color: 'blue', fontWeight: 'bold' };
      case 'scheduled':
        return { color: 'gray' };
      case 'late':
        return { color: 'red', fontWeight: 'bold' };
      default:
        return { color: 'black' };
    }
  };

  return (
    <div className="patient-list bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4"><TeamOutlined /> Patients</h2>
      <DatePicker
        value={selectedDate}
        onChange={(date) => setSelectedDate(date)}
        className="mb-4"
      />
      <List
        itemLayout="horizontal"
        dataSource={patients}
        renderItem={patient => (
          <List.Item onClick={() => handlePatientClick(patient.id)} className="cursor-pointer">
            <List.Item.Meta
              avatar={<Avatar src={patient.photoUrl} size={54} />}
              title={
                <div className="flex items-center">
                  <span style={{ fontSize: '1rem' }}>{`${patient.firstName} ${patient.lastName}`}</span>
                  <span style={getStatusStyle(patient.checkInStatus)} className="ml-2">
                    {patient.checkInStatus}
                  </span>
                </div>
              }
              description={patient.email}
            />
          </List.Item>
        )}
      />
    </div>
  );
};

export default PatientListComponent;