import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../../firebase';
import { sendSignInLinkToEmail } from 'firebase/auth';
import logo from '../../assets/logo.png';
const SignUp = () => {
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('doctor');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const actionCodeSettings = {
    url: `${process.env.REACT_APP_DOMAIN_URL}/finishSignUp`,
    handleCodeInApp: true,
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      window.localStorage.setItem('emailForSignIn', email);
      setMessage('Check your email for the sign-up link');
      setError('');
    } catch (error) {
      setError(error.message);
      setMessage('');
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 space-y-3 bg-white rounded-lg shadow-md">
        {/* Logo Section */}
        <div className="flex justify-center mb-4">
          <img src={logo} alt="Logo" className="h-16" /> {/* Adjust height as needed */}
        </div>
        <h1 className="text-2xl font-bold text-center">Register</h1>
        {message && <p className="text-green-500">{message}</p>}
        {error && <p className="text-red-500">{error}</p>}
        <form onSubmit={handleSignUp} className="space-y-6">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
              required
            />
          </div>
          <div>
            <label htmlFor="role" className="block text-sm font-medium text-gray-700">Role</label>
            <select
              id="role"
              value={role}
              onChange={(e) => setRole(e.target.value)}
              className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
              required
            >
              <option value="managingDoctor">Managing Doctor</option>
              <option value="doctor">Doctor</option>
              <option value="frontOffice">Front Office</option>
              <option value="massage">Massage</option>
              <option value="laser">Laser</option>
              <option value="admin">Admin</option>
            </select>
          </div>
          <button
            type="submit"
            className="w-full px-4 py-2 font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Send Sign-Up Link
          </button>
        </form>
        <div className="mt-4 text-center">
          <Link to="/signin" className="text-sm text-indigo-600 hover:underline">Already have an account? Sign In</Link>
        </div>
        <div className="mt-4 text-center">
          <Link to="/patient-signup" className="text-sm text-indigo-600 hover:underline">Patient Sign Up</Link>
        </div>
        <div className="mt-4 text-center">
          <Link to="/phone-signup" className="text-sm text-indigo-600 hover:underline">Sign up with Phone</Link>
        </div>
      </div>
    </div>
  );
};

export default SignUp;