import React, { useContext, useEffect, useState } from 'react';
import { Form, Select, Button, Divider, message } from 'antd';
import { useAuth } from '../../context/AuthContext';
import { ThemeContext } from '../../context/ThemeContext';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';

const { Option } = Select;

const Settings = () => {
  const { currentUser, resetPassword } = useAuth();
  const { theme, setTheme } = useContext(ThemeContext);
  const [preferredHomepage, setPreferredHomepage] = useState('/patients');

  useEffect(() => {
    if (currentUser) {
      fetchUserData(currentUser.uid);
    }
  }, [currentUser]);

  const fetchUserData = async (uid) => {
    try {
      const userDoc = await getDoc(doc(db, 'users', uid));
      if (userDoc.exists()) {
        const data = userDoc.data();
        setPreferredHomepage(data.preferredHomepage || '/patients');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const handleThemeChange = (value) => {
    setTheme(value);
    message.success(`Theme set to ${value}`);
  };

  const handlePasswordReset = async () => {
    try {
      await resetPassword(currentUser.email);
      message.success('Password reset email sent!');
    } catch (error) {
      message.error('Failed to send password reset email.');
    }
  };

  const handleHomepageChange = async (value) => {
    try {
      const userRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userRef, { preferredHomepage: value });
      setPreferredHomepage(value);
      message.success(`Homepage set to ${value === '/patients' ? 'Patient List' : 'Provider Dashboard'}`);
    } catch (error) {
      message.error('Failed to update preferred homepage.');
    }
  };

  return (
    <div className="max-w-2xl dark:bg-gray-800 dark:text-white mx-auto p-6 bg-white rounded-lg shadow-md mt-8">
      <h1 className="text-3xl font-bold mb-6">Settings</h1>
      <Form layout="vertical">
        <Form.Item label="Theme">
          <Select value={theme} onChange={handleThemeChange}>
            <Option value="light">Light</Option>
            <Option value="dark">Dark</Option>
            <Option value="system">System</Option>
          </Select>
        </Form.Item>
        <Divider />
        <Form.Item label="Homepage">
          <Select value={preferredHomepage} onChange={handleHomepageChange}>
            <Option value="/patients">Patient List</Option>
            <Option value="/provider-dashboard">Provider Dashboard</Option>
          </Select>
        </Form.Item>
        <Divider />
        <Form.Item>
          <Button type="primary" onClick={handlePasswordReset}>
            Reset Password
          </Button>
        </Form.Item>
        <Divider />
        {/* Add more settings here */}
      </Form>
    </div>
  );
};

export default Settings;