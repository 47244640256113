import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, query, where, updateDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';
import dayjs from 'dayjs';
import { InfoCircleOutlined, DollarOutlined, HeartFilled, TeamOutlined, FileDoneOutlined, EditOutlined, FolderOutlined, MedicineBoxOutlined, DownOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Button, message, Switch } from 'antd';
import { useAuth } from '../../context/AuthContext';

const PatientList = ({ currentUser }) => {
  const [patients, setPatients] = useState([]);
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [showTodayPatients, setShowTodayPatients] = useState(true);
  const [showMyPatients, setShowMyPatients] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'));
  const navigate = useNavigate();
  const [todayAppointments, setTodayAppointments] = useState([]);
  // const { currentUser } = useAuth();

  useEffect(() => {
    const fetchPatients = async () => {
      const patientsCollection = collection(db, 'patients');
      const patientSnapshot = await getDocs(patientsCollection);
      const patientList = patientSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      patientList.sort((a, b) => a.lastName.localeCompare(b.lastName));

      setPatients(patientList);
      setFilteredPatients(patientList);
      setLoading(false);
    };

    const fetchTodayAppointments = async () => {
      const appointmentsQuery = query(collection(db, 'appointments'), where('date', '==', selectedDate));
      const appointmentSnapshot = await getDocs(appointmentsQuery);
      const appointmentsData = appointmentSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setTodayAppointments(appointmentsData);
    };

    fetchPatients();
    fetchTodayAppointments();
  }, [selectedDate]);

  useEffect(() => {
    const regex = new RegExp(searchTerm, 'i');
    const filtered = patients.filter(patient =>
      regex.test(patient.firstName) ||
      regex.test(patient.lastName) ||
      regex.test(patient.dateOfBirth) ||
      regex.test(patient.contact) ||
      regex.test(patient.email) ||
      regex.test(patient.medicalRecordNumber)
    );
    setFilteredPatients(filtered);
  }, [searchTerm, patients]);

  const handlePatientClick = (id) => {
    navigate(`/dashboard/${id}`);
  };

  const handleOnboardNewPatient = () => {
    navigate('/onboard-patient');
  };

  const handleInvitePatient = () => {
    navigate('/patient-invite');
  };

  const handleCheckIn = async (appointmentId) => {
    try {
      await updateDoc(doc(db, 'appointments', appointmentId), { checkInStatus: 'checked-in' });
      message.success('Patient checked in successfully.');
      setTodayAppointments(prevAppointments =>
        prevAppointments.map(app =>
          app.id === appointmentId ? { ...app, checkInStatus: 'checked-in' } : app
        )
      );
    } catch (error) {
      console.error('Check-in failed', error);
      message.error('Failed to check in patient.');
    }
  };

  const handleCheckOut = async (appointmentId) => {
    const checkedOutAt = dayjs().toISOString();
    try {
      await updateDoc(doc(db, 'appointments', appointmentId), { checkInStatus: 'checked-out', checkedOutAt });
      message.success('Patient checked out successfully.');
      setTodayAppointments(prevAppointments =>
        prevAppointments.map(app =>
          app.id === appointmentId ? { ...app, checkInStatus: 'checked-out', checkedOutAt } : app
        )
      );
    } catch (error) {
      console.error('Check-out failed', error);
      message.error('Failed to check out patient.');
    }
  };

  const menu = (id) => {
    const appointment = todayAppointments.find(app => app.patientId === id);

    return (
      <Menu>
        {appointment && appointment.checkInStatus !== 'checked-out' && (
          <>
            <Menu.Item key="0" icon={<CheckCircleOutlined />}>
              <a onClick={() => {
                if (appointment.checkInStatus === 'scheduled') {
                  handleCheckIn(appointment.id);
                } else if (appointment.checkInStatus === 'checked-in') {
                  handleCheckOut(appointment.id);
                }
              }}>
                {appointment.checkInStatus === 'scheduled' ? 'Check In' : 'Check Out'}
              </a>
            </Menu.Item>
            <Menu.Divider />
          </>
        )}
        <Menu.Item key="1" icon={<InfoCircleOutlined />}>
        <a onClick={() => navigate(`/patient-information/${id}`)}>Patient Information</a>
        </Menu.Item>
        <Menu.Item key="2" icon={<FileDoneOutlined />}>
          <a onClick={() => navigate(`/view-forms/${id}`)}>View Forms</a>
        </Menu.Item>
        <Menu.Item key="3" icon={<FolderOutlined />}>
          <a onClick={() => navigate(`/upload-medical-records/${id}`)}>Medical Records</a>
        </Menu.Item>
        <Menu.Item key="4" icon={<EditOutlined />}>
          <a onClick={() => navigate(`/patient-notes/${id}`)}>Patient Notes</a>
        </Menu.Item>
        <Menu.Item key="5" icon={<HeartFilled />}>
          <a onClick={() => navigate(`/vitals/${id}`)}>Vitals</a>
        </Menu.Item>
        <Menu.Item key="6" icon={<MedicineBoxOutlined />}>
          <a onClick={() => navigate(`/prescription-management/${id}`)}>Prescriptions</a>
        </Menu.Item>
        <Menu.Item key="7" icon={<DollarOutlined />}>
        <a onClick={() => navigate(`/patient-payment/${id}`)}>Payment</a>
        </Menu.Item>
      </Menu>
    );
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const getStatusColor = (appointment) => {
    const currentTime = dayjs();
    const appointmentStartTime = dayjs(appointment.start.toDate());
    const appointmentSoonThreshold = appointmentStartTime.subtract(15, 'minutes');

    if (appointment.checkInStatus === 'checked-in') {
      return 'bg-green-500';
    } else if (currentTime.isAfter(appointmentStartTime) && appointment.checkInStatus === 'checked-out') {
      return 'bg-blue-500';
    } else if (currentTime.isAfter(appointmentStartTime)) {
      return 'bg-red-500';
    } else if (currentTime.isAfter(appointmentSoonThreshold) && currentTime.isBefore(appointmentStartTime)) {
      return 'bg-yellow-500';
    } else if (appointment.checkInStatus === 'scheduled') {
      return 'bg-purple-500';
    }
    return 'bg-gray-300';
  };

  const patientsWithAppointments = filteredPatients.filter(patient => 
    todayAppointments.some(appointment => appointment.patientId === patient.id)
  );

  const myPatientsToday = filteredPatients.filter(patient =>
    todayAppointments.some(appointment => appointment.patientId === patient.id && appointment.providerId === currentUser.uid)
  );

  const patientsToDisplay = showTodayPatients ? (showMyPatients ? myPatientsToday : patientsWithAppointments) : filteredPatients;

  return (
    <div className="p-6 bg-white dark:bg-gray-900 rounded-lg shadow-md max-w-4xl mx-auto">    
      <h1 className="text-2xl text-indigo-900 dark:text-indigo-300 font-bold text-left mb-4"><TeamOutlined /> Patient List</h1>
      <div className="flex flex-col sm:flex-row justify-between mb-4">
        <div className="flex items-center mb-2 sm:mb-0">
          {showTodayPatients && (
            <Switch
              checked={showMyPatients}
              onChange={setShowMyPatients}
              checkedChildren="Me"
              unCheckedChildren="All"
              className="ml-2 mr-2"
            />
          )}
          <button
            onClick={() => { setShowTodayPatients(true); }}
            className={`px-4 py-2 font-medium ${showTodayPatients ? 'bg-blue-600 text-white' : 'bg-gray-200 dark:bg-gray-700 dark:text-white'} rounded-md`}
          >
            Today's Patients
          </button>
          <button
            onClick={() => { setShowTodayPatients(false); setShowMyPatients(false); }}
            className={`ml-2 px-4 py-2 font-medium ${!showTodayPatients ? 'bg-blue-600 text-white' : 'bg-gray-200 dark:bg-gray-700 dark:text-white'} rounded-md`}
          >
            All Patients
          </button>
        </div>
        {showTodayPatients && (
          <input
            type="date"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
            className="ml-4 px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-800 text-black dark:text-white"
          />
        )}
      </div>
      <input
        type="text"
        placeholder="Search by name, birthdate, MRN, phone, or email"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="mb-4 px-4 py-2 w-full border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-800 text-black dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-indigo-300"
      />
      <button
        onClick={handleInvitePatient}
        className="w-1/2 px-4 py-2 font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mb-4"
      >
        Invite Patient
      </button>
      <button
        onClick={handleOnboardNewPatient}
        className="w-1/2 px-4 py-2 font-medium text-white bg-green-600 rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 mb-4"
      >
        Onboard Patient
      </button>
      <ul className="space-y-4">
        {patientsToDisplay.map((patient) => {
          const appointment = todayAppointments.find(app => app.patientId === patient.id);
          const statusColor = appointment ? getStatusColor(appointment) : 'bg-gray-300';

          return (
            <li key={patient.id} className="flex items-center p-4 bg-gray-100 dark:bg-gray-800 rounded-md w-full">
              {patient.photoUrl && (
                <img src={patient.photoUrl} alt="Patient" className="w-16 h-16 rounded-full mr-4" />
              )}
              <div className="flex-grow" onClick={() => handlePatientClick(patient.id)}>
                <p className="text-lg font-semibold text-black dark:text-white">{patient.lastName}, {patient.firstName}</p>
                <p className="text-gray-600 dark:text-gray-400">{patient.dateOfBirth}</p>
              </div>
              {showTodayPatients && (
                <div className={`w-3 h-3 rounded-full ${statusColor}`}></div>
              )}
              <Dropdown overlay={menu(patient.id)} trigger={['click']}>
                <Button className="ml-4 dark:bg-gray-700 dark:text-white">
                  Quick Action <DownOutlined />
                </Button>
              </Dropdown>
            </li>
          );
        })}
      </ul>
      {showTodayPatients && (
        <div className="mt-6">
          <h2 className="text-lg font-bold text-black dark:text-white">Legend</h2>
          <div className="flex flex-wrap space-x-4 space-y-2 mt-2">
            <div className="flex items-center">
              <div className="w-4 h-4 bg-purple-500 mr-2 rounded"></div>
              <span className="text-black dark:text-white">Scheduled</span>
            </div>
            <div className="flex items-center">
              <div className="w-4 h-4 bg-yellow-500 mr-2 rounded"></div>
              <span className="text-black dark:text-white">Appointment Soon</span>
            </div>
            <div className="flex items-center">
              <div className="w-4 h-4 bg-green-500 mr-2 rounded"></div>
              <span className="text-black dark:text-white">Checked In</span>
            </div>
            <div className="flex items-center">
              <div className="w-4 h-4 bg-red-500 mr-2 rounded"></div>
              <span className="text-black dark:text-white">Late</span>
            </div>
            <div className="flex items-center">
              <div className="w-4 h-4 bg-blue-500 mr-2 rounded"></div>
              <span className="text-black dark:text-white">Checked Out</span>
            </div>
            <div className="flex items-center">
              <div className="w-4 h-4 bg-gray-300 mr-2 rounded"></div>
              <span className="text-black dark:text-white">No Status</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PatientList;