import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Popover } from 'antd';

const localizer = momentLocalizer(moment);

const ScheduleComponent = ({ events }) => {
  // Function to render event with Popover
  const EventComponent = ({ event }) => {
    const content = (
      <div>
        <p><strong>Title:</strong> {event.title}</p>
        <p><strong>Reason:</strong> {event.reason}</p>
        <p><strong>Visit Type:</strong> {event.visitType}</p>
        <p><strong>Start:</strong> {moment(event.start).format('MMMM Do YYYY, h:mm:ss a')}</p>
        <p><strong>End:</strong> {moment(event.end).format('MMMM Do YYYY, h:mm:ss a')}</p>
        <p><strong>Check-in Status:</strong> {event.checkInStatus}</p>
      </div>
    );

    return (
      <Popover content={content} title="Appointment Details">
        <div>{event.title}</div>
      </Popover>
    );
  };

  return (
    <div className="schedule bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4">My Appoinments</h2>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        components={{
          event: EventComponent,
        }}
      />
    </div>
  );
};

export default ScheduleComponent;