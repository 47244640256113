import React from 'react';
import { Avatar } from 'antd';

const ProfileInfo = ({ user }) => {
  if (!user) return null;

  return (
    <div className="profile-info bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md">
      <div className="flex items-center">
        <Avatar src={user.photoURL} size={64} className="mr-4" />
        <div>
          <h2 className="text-xl font-bold">{user.displayName || user.email}</h2>
          <p className="text-gray-600 dark:text-gray-400">{user.email}</p>
        </div>
      </div>
    </div>
  );
};

export default ProfileInfo;