import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { collection, getDocs, query, where, doc, getDoc, orderBy } from 'firebase/firestore';
import { db } from '../../firebase';
import { Modal, Button } from 'antd';
import ProfileInfo from '../common/ProfileInfo';
import PatientListComponent from '../lists/PatientListComponent';
import ScheduleComponent from '../dashboard/ScheduleComponent';
import InternalMessagesDashboard from '../pages/InternalMessagesDashboard';

const ProviderDashboard = () => {
  const { currentUser } = useAuth();
  const [userData, setUserData] = useState(null);
  const [patientsToday, setPatientsToday] = useState([]);
  const [messages, setMessages] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (currentUser) {
      fetchUserData(currentUser.uid);
    }
  }, [currentUser]);

  const fetchUserData = async (uid) => {
    try {
      const userDoc = await getDoc(doc(db, 'users', uid));
      if (userDoc.exists()) {
        const data = userDoc.data();
        setUserData(data);
        fetchPatientsToday(data.uid);
        fetchMessages(data.email);
        fetchSchedule(data.uid);
      } else {
        console.error('No user data found');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const fetchPatientsToday = async (providerId) => {
    if (!providerId) return;
    try {
      const today = new Date().toISOString().split('T')[0];
      const q = query(
        collection(db, 'appointments'),
        where('providerId', '==', providerId),
        where('date', '==', today)
      );
      const snapshot = await getDocs(q);

      const patients = await Promise.all(snapshot.docs.map(async (appointmentDoc) => {
        const data = appointmentDoc.data();
        const patientRef = doc(db, 'patients', data.patientId);
        const patientDoc = await getDoc(patientRef);
        const patientData = patientDoc.exists() ? patientDoc.data() : {};

        return {
          id: appointmentDoc.id,
          firstName: patientData.firstName || 'N/A',
          lastName: patientData.lastName || 'N/A',
          email: patientData.email || 'N/A',
          photoUrl: patientData.photoUrl || ''
        };
      }));

      setPatientsToday(patients);
    } catch (error) {
      console.error('Error fetching today\'s patients:', error);
    }
  };

  const fetchMessages = async (email) => {
    if (!email) return;
    try {
      const q = query(
        collection(db, 'internalMessages'),
        where('recipient', '==', email)
      );
      const snapshot = await getDocs(q);
      const messages = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setMessages(messages);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const fetchSchedule = async (providerId) => {
    if (!providerId) return;
    try {
      const eventsCollection = collection(db, 'appointments');
      const eventsSnapshot = await getDocs(eventsCollection);
      const eventsList = await Promise.all(eventsSnapshot.docs.map(async (eventDoc) => {
        const data = eventDoc.data();
        if (data.providerId !== providerId) return null; // Filter by providerId

        const patientDocRef = doc(db, 'patients', data.patientId);
        const patientDoc = await getDoc(patientDocRef);

        if (patientDoc.exists()) {
          const patientData = patientDoc.data();
          return {
            ...data,
            title: `${data.reason} - ${patientData.firstName} ${patientData.lastName}`,
            start: data.start ? new Date(data.start.seconds * 1000) : new Date(),
            end: data.end ? new Date(data.end.seconds * 1000) : new Date(),
            id: eventDoc.id,
            photoUrl: patientData.photoUrl
          };
        } else {
          console.warn(`No patient found for ID: ${data.patientId}`);
          return {
            ...data,
            title: `${data.reason} - Unknown Patient`,
            start: data.start ? new Date(data.start.seconds * 1000) : new Date(),
            end: data.end ? new Date(data.end.seconds * 1000) : new Date(),
            id: eventDoc.id
          };
        }
      }));

      setSchedule(eventsList.filter(event => event !== null));
    } catch (error) {
      console.error('Error fetching schedule:', error);
    }
  };

  const handleAppointmentClick = async (appointment) => {
    try {
      const patientDoc = await getDoc(doc(db, 'patients', appointment.patientId));
      const providerDoc = await getDoc(doc(db, 'users', appointment.providerId));
      const patientData = patientDoc.exists() ? patientDoc.data() : {};
      const providerData = providerDoc.exists() ? providerDoc.data() : {};

      setSelectedAppointment({
        ...appointment,
        patientName: `${patientData.firstName} ${patientData.lastName}`,
        providerName: `${providerData.firstName} ${providerData.lastName}`,
      });
      setIsModalVisible(true);
    } catch (error) {
      console.error('Error fetching appointment details:', error);
    }
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedAppointment(null);
  };

  return (
    <div className="provider-dashboard p-6 bg-white dark:bg-gray-900 rounded-lg shadow-md max-w-7xl mx-auto">
      <h1 className="text-3xl font-bold mb-6">Provider Dashboard</h1>
      {userData && <ProfileInfo user={userData} />}
      <div className="mt-6">
        <h2 className="text-2xl font-bold mb-4">Today's Patients</h2>
        {userData ? <PatientListComponent providerId={userData.uid} /> : <p>Loading...</p>}
      </div>
      <div className="mt-6">
        <ScheduleComponent events={schedule} onEventClick={handleAppointmentClick} />
      </div>
      <div className="mt-6">
        <InternalMessagesDashboard messages={messages} />
      </div>

      <Modal
        title="Appointment Details"
        open={isModalVisible}
        onCancel={handleModalClose}
        footer={[
          <Button key="close" onClick={handleModalClose}>
            Close
          </Button>,
        ]}
      >
        {selectedAppointment && (
          <div>
            <p><strong>Title:</strong> {selectedAppointment.title}</p>
            <p><strong>Reason:</strong> {selectedAppointment.reason}</p>
            <p><strong>Visit Type:</strong> {selectedAppointment.visitType}</p>
            <p><strong>Start:</strong> {selectedAppointment.start.toLocaleString()}</p>
            <p><strong>End:</strong> {selectedAppointment.end.toLocaleString()}</p>
            <p><strong>Patient:</strong> {selectedAppointment.patientName}</p>
            <p><strong>Provider:</strong> {selectedAppointment.providerName}</p>
            <p><strong>Check-in Status:</strong> {selectedAppointment.checkInStatus}</p>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ProviderDashboard;