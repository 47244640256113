import React, { useState, useEffect } from 'react';
import { RecaptchaVerifier, signInWithPhoneNumber, PhoneAuthProvider, signInWithCredential } from 'firebase/auth';
import { message } from 'antd';
import { auth } from '../../firebase'; // Adjust the path as necessary

const PhoneSignIn = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationId, setVerificationId] = useState('');

  useEffect(() => {
    if (!window.recaptchaVerifier) {
      try {
        window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
          size: 'normal',
          callback: (response) => {
            console.log("reCAPTCHA solved, response:", response);
          },
          'expired-callback': () => {
            console.log("reCAPTCHA expired, resetting...");
            window.recaptchaVerifier.reset();
          }
        }, auth);
        window.recaptchaVerifier.render().then((widgetId) => {
          console.log("reCAPTCHA rendered with widget ID:", widgetId);
        }).catch((error) => {
          console.error("Error rendering reCAPTCHA:", error);
        });
      } catch (error) {
        console.error("Error initializing RecaptchaVerifier:", error);
      }
    }
  }, []);

  const handleSignIn = async () => {
    console.log("handleSignIn called");
    const appVerifier = window.recaptchaVerifier;
    if (!appVerifier) {
      console.error("reCAPTCHA verifier is not initialized.");
      return;
    }
    try {
      const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
      console.log("Formatted phone number:", formattedPhoneNumber);
      const confirmationResult = await signInWithPhoneNumber(auth, formattedPhoneNumber, appVerifier);
      setVerificationId(confirmationResult.verificationId);
      message.success('Verification code sent to your phone.');
    } catch (error) {
      console.error("Error during signInWithPhoneNumber:", error);
      message.error(error.message);
    }
  };

  const verifyCode = async () => {
    try {
      const credential = PhoneAuthProvider.credential(verificationId, verificationCode);
      await signInWithCredential(auth, credential);
      message.success('Phone number verified and signed in!');
    } catch (error) {
      console.error("Error during signInWithCredential:", error);
      message.error(error.message);
    }
  };

  const formatPhoneNumber = (number) => {
    const cleaned = ('' + number).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{1,3})(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `+${match[1]}${match[2]}${match[3]}${match[4]}`;
    }
    return number;
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (!verificationId) {
        handleSignIn();
      } else {
        verifyCode();
      }
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 space-y-3 bg-white rounded-lg shadow-md">
        <h1 className="text-2xl font-bold text-center">Phone Sign In</h1>
        <div id="recaptcha-container"></div>
        <input
          type="text"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          placeholder="Enter phone number"
          className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
          onKeyPress={handleKeyPress}
        />
        <button onClick={handleSignIn} className="w-full px-4 py-2 font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700">
          Send Verification Code
        </button>
        <input
          type="text"
          value={verificationCode}
          onChange={(e) => setVerificationCode(e.target.value)}
          placeholder="Enter verification code"
          className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100"
          onKeyPress={handleKeyPress}
        />
        <button onClick={verifyCode} className="w-full px-4 py-2 font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700">
          Verify Code
        </button>
      </div>
    </div>
  );
};

export default PhoneSignIn;