import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { collection, getDocs, query, where, orderBy } from 'firebase/firestore';
import { db } from '../../firebase';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

const AppointmentHistoryCalendar = ({ patientId }) => {
    const [appointments, setAppointments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [date, setDate] = useState(new Date());
    const [selectedAppointment, setSelectedAppointment] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        const fetchAppointments = async () => {
            try {
                const q = query(collection(db, 'appointments'), where('patientId', '==', patientId), orderBy('start', 'desc'));
                const querySnapshot = await getDocs(q);
                const appointmentsList = await Promise.all(querySnapshot.docs.map(async (doc) => {
                    const data = doc.data();
                    return {
                        id: doc.id,
                        ...data,
                        start: data.start ? new Date(data.start.seconds * 1000) : new Date(), // Convert Firestore timestamp to Date object
                        end: data.end ? new Date(data.end.seconds * 1000) : new Date() // Convert end timestamp to Date object if needed
                    };
                }));
                // console.log("Fetched appointments:", appointmentsList); // Debugging line
                setAppointments(appointmentsList);
            } catch (error) {
                console.error('Error fetching appointments:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchAppointments();
    }, [patientId]);

    const handleDateChange = (newDate) => {
        setDate(newDate);
        console.log("Selected date:", newDate); // Debugging line
    };

    const handleEventClick = (event) => {
        setSelectedAppointment(event);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setSelectedAppointment(null);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md">
            <h2 className="text-xl font-bold mb-4">Appointment History Calendar</h2>
            <Calendar
                localizer={localizer}
                events={appointments}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 400 }}
                selectable
                onSelectEvent={handleEventClick} // Handle event click
                onSelectSlot={slotInfo => handleDateChange(slotInfo.start)} // Handle date selection
            />
            <div className="mt-4">
                <h3 className="font-semibold">Appointments on {date.toLocaleDateString()}:</h3>
                <ul className="space-y-2">
                    {appointments.filter(appointment => {
                        const appointmentDate = new Date(appointment.start);
                        return appointmentDate.toLocaleDateString() === date.toLocaleDateString();
                    }).map(appointment => (
                        <li key={appointment.id} className="bg-gray-100 p-4 rounded-md shadow">
                            <p><strong>Time:</strong> {new Date(appointment.start).toLocaleTimeString()}</p>
                            <p><strong>Reason:</strong> {appointment.reason}</p>
                            <p><strong>Provider:</strong> {appointment.provider}</p>
                            <p><strong>Visit Type:</strong> {appointment.visitType}</p>
                        </li>
                    ))}
                </ul>
            </div>

            {/* Modal for Appointment Details */}
            {modalOpen && selectedAppointment && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-50"> {/* Ensure z-index is high */}
                    <div className="bg-white p-6 rounded-lg shadow-lg z-60"> {/* Ensure z-index is high */}
                        <h2 className="text-xl font-bold mb-4">Appointment Details</h2>
                        <p><strong>Time:</strong> {new Date(selectedAppointment.start).toLocaleString()}</p>
                        <p><strong>Reason:</strong> {selectedAppointment.reason}</p>
                        <p><strong>Provider:</strong> {selectedAppointment.provider}</p>
                        <p><strong>Visit Type:</strong> {selectedAppointment.visitType}</p>
                        <p><strong>Notes:</strong> {selectedAppointment.notes || 'No additional notes'}</p>
                        <button onClick={closeModal} className="mt-4 px-4 py-2 bg-blue-600 text-white rounded">Close</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AppointmentHistoryCalendar;