import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../../firebase';
import { signInWithEmailAndPassword, sendSignInLinkToEmail } from 'firebase/auth';
import { message } from 'antd';
import logo from '../../assets/logo.png';

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [messageText, setMessageText] = useState('');
  const [error, setError] = useState('');
  const [useEmailLink, setUseEmailLink] = useState(false);
  const navigate = useNavigate();

  const actionCodeSettings = {
    url: `${process.env.REACT_APP_DOMAIN_URL}/finishSignUp`,
    handleCodeInApp: true,
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      if (useEmailLink) {
        await sendSignInLinkToEmail(auth, email, actionCodeSettings);
        window.localStorage.setItem('emailForSignIn', email);
        setMessageText('Check your email for the sign-in link');
      } else {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        message.success(`Welcome, ${user.displayName || email.split('@')[0]}!`);
        setTimeout(() => {
          navigate('/');
        }, 3000);
      }
      setError('');
    } catch (error) {
      setError(error.message);
      setMessageText('');
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 space-y-3 bg-white rounded-lg shadow-md">
        <div className="flex justify-center mb-4">
          <img src={logo} alt="Logo" className="h-16" />
        </div>
        <h1 className="text-2xl font-bold text-center">Sign In</h1>
        {messageText && <p className="text-green-500">{messageText}</p>}
        {error && <p className="text-red-500">{error}</p>}
        <form onSubmit={handleSignIn} className="space-y-6">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 text-black"
              required
            />
          </div>
          {!useEmailLink && (
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 text-black"
                required
              />
            </div>
          )}
          <div className="flex items-center">
            <input
              type="checkbox"
              id="useEmailLink"
              checked={useEmailLink}
              onChange={(e) => setUseEmailLink(e.target.checked)}
              className="mr-2"
            />
            <label htmlFor="useEmailLink" className="text-sm text-gray-700">Sign in using email link</label>
          </div>
          <button
            type="submit"
            className="w-full px-4 py-2 font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Sign In
          </button>
        </form>
        <div className="flex justify-between mt-4">
          <Link to="/signup" className="text-sm text-indigo-600 hover:underline">Register</Link>
          <Link to="/forgot-password" className="text-sm text-indigo-600 hover:underline">Forgot Password?</Link>
        </div>
        <div className="mt-4 text-center">
          <Link to="/phone-signin" className="text-sm text-indigo-600 hover:underline">Sign in with Phone</Link>
        </div>
      </div>
    </div>
  );
};

export default SignIn;