import React from 'react';
import { Card, Col, Row, Button, Typography, Divider } from 'antd';
import { CheckOutlined, CloseCircleOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const PricingPage = () => {
  const plans = [
    {
      name: 'Basic',
      price: '$49/month',
      features: [
        '10 Users',
        'Basic Support',
        'Email Notifications',
      ],
      buttonText: 'Get Started',
      buttonType: 'default',
    },
    {
      name: 'Professional',
      price: '$129/month',
      features: [
        '50 Users',
        'Priority Support',
        'SMS Notifications',
        'Custom Branding',
      ],
      buttonText: 'Most Popular',
      buttonType: 'primary',
    },
    {
      name: 'Enterprise',
      price: 'Contact Us',
      features: [
        'Unlimited Users',
        '24/7 Support',
        'Dedicated Account Manager',
        'Advanced Analytics',
      ],
      buttonText: 'Contact Sales',
      buttonType: 'default',
    },
  ];

  return (
    <div className="p-6 bg-white dark:bg-gray-900 rounded-lg shadow-md max-w-6xl mx-auto">
      <Title level={2} className="text-center text-indigo-900 dark:text-indigo-300 mb-8">
        Choose Your Plan
      </Title>
      <Row gutter={[16, 16]} justify="center">
        {plans.map((plan, index) => (
          <Col xs={24} md={8} key={index}>
            <Card
              hoverable
              className="flex flex-col justify-between h-full rounded-lg overflow-hidden shadow-lg dark:bg-gray-800"
            >
              <div className="p-6 text-center">
                <Title level={3} className="text-indigo-900 dark:text-indigo-300">{plan.name}</Title>
                <Text className="text-2xl font-bold text-black dark:text-white">
                  {plan.price}
                </Text>
                <div className="mt-4">
                  {plan.features.map((feature, idx) => (
                    <div key={idx} className="flex items-center justify-center mb-2">
                      <CheckOutlined className="text-green-500 mr-2" />
                      <span className="text-black dark:text-white">{feature}</span>
                    </div>
                  ))}
                </div>
              </div>
              <Button
                type={plan.buttonType}
                className="mt-6 w-full"
              >
                {plan.buttonText}
              </Button>
            </Card>
          </Col>
        ))}
      </Row>

      <Divider className="my-8" />

      <div className="text-center">
        <Title level={3} className="text-indigo-900 dark:text-indigo-300 mb-4">Feature Comparison</Title>
        <table className="w-full text-left border-collapse">
          <thead>
            <tr>
              <th className="p-2 border-b dark:border-gray-700">Feature</th>
              <th className="p-2 border-b dark:border-gray-700">Basic</th>
              <th className="p-2 border-b dark:border-gray-700">Professional</th>
              <th className="p-2 border-b dark:border-gray-700">Enterprise</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="p-2 border-b dark:border-gray-700">Users</td>
              <td className="p-2 border-b dark:border-gray-700">10</td>
              <td className="p-2 border-b dark:border-gray-700">50</td>
              <td className="p-2 border-b dark:border-gray-700">Unlimited</td>
            </tr>
            <tr>
              <td className="p-2 border-b dark:border-gray-700">Support</td>
              <td className="p-2 border-b dark:border-gray-700">Basic</td>
              <td className="p-2 border-b dark:border-gray-700">Priority</td>
              <td className="p-2 border-b dark:border-gray-700">24/7</td>
            </tr>
            <tr>
              <td className="p-2 border-b dark:border-gray-700">Notifications</td>
              <td className="p-2 border-b dark:border-gray-700">Email</td>
              <td className="p-2 border-b dark:border-gray-700">Email/Push/SMS</td>
              <td className="p-2 border-b dark:border-gray-700">Email/Push/SMS</td>
            </tr>
            <tr>
              <td className="p-2 border-b dark:border-gray-700">Branding</td>
              <td className="p-2 border-b dark:border-gray-700"><CloseCircleOutlined /></td>
              <td className="p-2 border-b dark:border-gray-700">Custom</td>
              <td className="p-2 border-b dark:border-gray-700">Custom</td>
            </tr>
          </tbody>
        </table>
      </div>

      <Divider className="my-8" />

      <div className="text-center">
        <Title level={3} className="text-indigo-900 dark:text-indigo-300 mb-4">Frequently Asked Questions</Title>
        <div className="max-w-4xl mx-auto">
          <div className="mb-4">
            <Title level={4} className="text-black dark:text-white">What is included in the Basic plan?</Title>
            <Text className="text-gray-700 dark:text-gray-300">
              The Basic plan includes access for up to 10 users, basic support, and email notifications.
            </Text>
          </div>
          <div className="mb-4">
            <Title level={4} className="text-black dark:text-white">Can I upgrade my plan later?</Title>
            <Text className="text-gray-700 dark:text-gray-300">
              Yes, you can upgrade your plan at any time by contacting our support team.
            </Text>
          </div>
          <div className="mb-4">
            <Title level={4} className="text-black dark:text-white">Is there a free trial available?</Title>
            <Text className="text-gray-700 dark:text-gray-300">
              We offer a 14-day free trial for all new users. No credit card required.
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingPage;