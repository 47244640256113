import React, { useEffect, useState } from "react";
import { ScheduleComponent, Day, Week, WorkWeek, Month, Agenda, Inject, ViewsDirective, ViewDirective } from "@syncfusion/ej2-react-schedule";
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase";
import dayjs from "dayjs";

const SchedulerComponent = () => {
  const [appointments, setAppointments] = useState([]);
  const [patients, setPatients] = useState([]);
  const [providers, setProviders] = useState([]);
  const [selectedPatientId, setSelectedPatientId] = useState("");
  const [selectedProviderId, setSelectedProviderId] = useState("");
  const [selectedVisitType, setSelectedVisitType] = useState("");
  const [reason, setReason] = useState("");

  useEffect(() => {
    fetchAppointments();
    fetchPatients();
    fetchProviders();
  }, []);

  const fetchAppointments = async () => {
    const querySnapshot = await getDocs(collection(db, "appointments"));
    const appointmentsData = querySnapshot.docs.map((doc) => {
      const data = doc.data();
      return {
        ...data,
        id: doc.id,
        StartTime: typeof data.start === "string" ? new Date(data.start) : data.start.toDate(),
        EndTime: typeof data.end === "string" ? new Date(data.end) : data.end.toDate(),
        Subject: data.title,
      };
    });
    setAppointments(appointmentsData);
  };
  
  // Fetch patients and sort alphabetically
  const fetchPatients = async () => {
    const querySnapshot = await getDocs(collection(db, "patients"));
    const patientsData = querySnapshot.docs
      .map((doc) => ({ id: doc.id, ...doc.data() }))
      .sort((a, b) => (a.firstName || "").localeCompare(b.firstName || ""));
    setPatients(patientsData);
  };

  // Fetch providers and sort alphabetically
  const fetchProviders = async () => {
    const querySnapshot = await getDocs(collection(db, "users"));
    const providersData = querySnapshot.docs
      .map((doc) => ({ id: doc.id, ...doc.data() }))
      .sort((a, b) => (a.firstName || "").localeCompare(b.firstName || ""));
    setProviders(providersData);
  };

  const handleActionComplete = async (args) => {
    if (args.requestType === "eventCreated") {
      const newEvent = args.addedRecords[0];
      await addDoc(collection(db, "appointments"), {
        checkInStatus: "scheduled",
        date: dayjs(newEvent.StartTime).tz('America/Los_Angeles').format('YYYY-MM-DD'),
        start: newEvent.StartTime, // Ensure Firestore recognizes this as a timestamp
        end: newEvent.EndTime, // Ensure Firestore recognizes this as a timestamp
        time: newEvent.StartTime.toLocaleTimeString("en-US", { hour12: false, hour: "2-digit", minute: "2-digit" }),
        timestamp: serverTimestamp(),
        patientId: selectedPatientId,
        providerId: selectedProviderId,
        reason: reason,
        title: newEvent.Subject || "",
        visitType: selectedVisitType,
      });
      fetchAppointments();
    }
  
    if (args.requestType === "eventChanged") {
      const updatedEvent = args.changedRecords[0];
      const eventRef = doc(db, "appointments", updatedEvent.id);
      await updateDoc(eventRef, {
        checkInStatus: "scheduled",
        date: dayjs(updatedEvent.StartTime).tz('America/Los_Angeles').format('YYYY-MM-DD'),
        start: updatedEvent.StartTime, // Ensure Firestore recognizes this as a timestamp
        end: updatedEvent.EndTime, // Ensure Firestore recognizes this as a timestamp
        time: updatedEvent.StartTime.toLocaleTimeString("en-US", { hour12: false, hour: "2-digit", minute: "2-digit" }),
        timestamp: serverTimestamp(),
        patientId: selectedPatientId,
        providerId: selectedProviderId,
        reason: reason,
        title: updatedEvent.Subject || "",
        visitType: selectedVisitType,
      });
      fetchAppointments();
    }
  };

  const onPopupOpen = (args) => {
    if (args.type === "Editor") {
      const formElement = args.element.querySelector(".e-schedule-form");

      if (!formElement.querySelector("#customFields")) {
        const customFields = document.createElement("div");
        customFields.id = "customFields";
        customFields.className = "mt-4 space-y-4";

        customFields.innerHTML = `
          <div>
            <label class="block text-sm font-medium mb-1">Patient:</label>
            <select id="patientId" class="w-full border rounded-md p-2">
              ${patients
                .map((patient) => `<option value="${patient.id}">${patient.firstName} ${patient.lastName}</option>`)
                .join("")}
            </select>
          </div>

          <div>
            <label class="block text-sm font-medium mb-1">Provider:</label>
            <select id="providerId" class="w-full border rounded-md p-2">
              ${providers
                .map((provider) => `<option value="${provider.id}">${provider.firstName} ${provider.lastName}</option>`)
                .join("")}
            </select>
          </div>

          <div>
            <label class="block text-sm font-medium mb-1">Visit Type:</label>
            <select id="visitType" class="w-full border rounded-md p-2">
              <option value="chiropractic">Chiropractic</option>
              <option value="massage">Massage</option>
              <option value="evaluation">Evaluation</option>
              <option value="second visit">Second Visit</option>
              <option value="laser">Laser</option>
            </select>
          </div>

          <div>
            <label class="block text-sm font-medium mb-1">Reason:</label>
            <textarea id="reason" class="w-full border rounded-md p-2 h-24 resize-y"></textarea>
          </div>
        `;

        formElement.appendChild(customFields);

        document.getElementById("patientId").addEventListener("change", (e) => setSelectedPatientId(e.target.value));
        document.getElementById("providerId").addEventListener("change", (e) => setSelectedProviderId(e.target.value));
        document.getElementById("visitType").addEventListener("change", (e) => setSelectedVisitType(e.target.value));
        document.getElementById("reason").addEventListener("input", (e) => setReason(e.target.value));
      }
    }
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Patient Scheduler</h2>
      <ScheduleComponent
        height='78vh'
        currentView='Week'
        eventSettings={{ dataSource: appointments }}
        actionComplete={handleActionComplete}
        popupOpen={onPopupOpen}
      >
        <ViewsDirective>
          <ViewDirective option='Day' startHour='7:00' endHour='22:00' />
          {/* <ViewDirective option='Day' /> */}
          {/* <ViewDirective option='WorkWeek' startHour='7:00' endHour='22:00'/> */}
          <ViewDirective option='Week' startHour='07:00' endHour='22:00'/>
          <ViewDirective option='Month' showWeekend={true}/>
          <ViewDirective option='Agenda' />
        </ViewsDirective>
        <Inject services={[Day, Week, WorkWeek, Month, Agenda]} />
      </ScheduleComponent>
    </div>
  );
};

export default SchedulerComponent;